import { timezoneListModel } from 'app/models/timezones';
import Table from 'app/components/simple-table/simple-table';
import { editUserPermissions } from './people.controller';
import { Ball, StatusWrapper, Container } from './people.style';
import { ProjectType } from 'app/modules/project/project.interfaces';

interface PeopleProps {
  isAdministrator: boolean;
  token: string;
  project: ProjectType;
  onPermissionChanged: ({ name, role }: { name: string; role: string }) => void;
}

const options = [
  {
    id: 'project-manager',
    label: 'Project Manager',
  },
  {
    id: 'editor',
    label: 'Editor',
  },
  {
    id: 'observer',
    label: 'Observer',
  },
];

const Status = ({ online }: { online: boolean }) => (
  <StatusWrapper>
    <Ball online={online} />
    {online ? 'Online' : 'Offline'}
  </StatusWrapper>
);

/**
 * 
 * @param param0 
 * @returns 
 */
const People = ({
  project,
  token,
  onPermissionChanged,
  isAdministrator,
}: PeopleProps) => {
  return (
    <Container>
      <Table
        rows={project.participant_set.filter((participant) => !!participant)}
        columns={[
          {
            headerName: 'Status',
            field: 'status',
            width: 100,
            renderCell: ({ online }: { online: boolean }) => Status({ online }),
          },
          {
            headerName: 'Name',
            field: 'name',
            valueFormatter: (value: string, row: any) =>
              `${row?.user?.first_name} ${row?.user?.last_name} ${row?.user?.is_guest ? '(Guest)' : ''}`,
            width: 300,
          },
          {
            headerName: 'Email',
            field: 'email',
            valueFormatter: (value: string, row: any) => row.user?.is_guest ? '-' : row.user?.email,
            width: 250,
            ellipsis: true,
          },
          {
            headerName: 'Phone',
            width: 200,
            valueFormatter: (value: string, row: any) => row.user?.phone_number,
            field: 'phone',
          },
          {
            headerName: 'Role',
            field: 'role',
            width: 200,
            type: isAdministrator ? 'selector' : undefined,
            handleChange: ({ id }: any, row: any) => {
              const permissions = {
                'project-manager': {
                  label: 'Project Manager',
                  admin: true,
                  editor: true,
                },
                editor: {
                  label: 'editor',
                  admin: false,
                  editor: true,
                },
                observer: {
                  label: 'Observer',
                  admin: false,
                  editor: false,
                },
              };

              editUserPermissions(
                project.id.toString(),
                permissions[id as 'project-manager' | 'editor' | 'observer']
                  .admin,
                permissions[id as 'project-manager' | 'editor' | 'observer']
                  .editor,
                row.user.id,
                token,
                () =>
                  onPermissionChanged({
                    name: `${row?.user?.first_name} ${row?.user?.last_name}`,
                    role: permissions[
                      id as 'project-manager' | 'editor' | 'observer'
                    ].label,
                  })
              );
            },
            options,
            valueFormatter: (value: string, row: any) => {
              let id = 'observer';
              if (row.editor && !row.admin) id = 'editor';
              if (row.admin) id = 'project-manager';

              if (isAdministrator) return id;
              return options.find((option) => option.id === id)?.label || '';
            },
          },
          {
            headerName: 'Timezone',
            field: 'timezone',
            width: 200,
            valueFormatter: (value: string, row: any) =>
              timezoneListModel.find(({ location }: { location: string }) => row?.user?.timezone === location)?.name || value,
          },
        ]}
      />
    </Container>
  );
}

People.defaultProps = {
  participants: [],
};

export default People;
