import styled from 'styled-components';

export const ProjectCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;

  height: 42px;

  position: fixed;
  right: 16px;
  top: 0;

  z-index: 50;

  > label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    color: #090808;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    
    background-color: transparent;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid #a4abb6;
    
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #323030;

    cursor: pointer;

    > span {
      background-color: #090808;
      color: #ffffff;
      border-radius: 4px;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: 400;

      text-transform: capitalize;
    }
  }
`;
