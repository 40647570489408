import { useEffect, useState } from "react";
import { CodeInputContainer } from "./code-input.style";

interface Props {
  length: number;
  onChange: (code: string) => void;
}

/**
 * 
 * @returns 
 */
const CodeInput = (props: Props) => {
  const [code, setCode] = useState<string[]>(new Array(props.length).fill(''));

  useEffect(() => {
    props.onChange(code.join(''));
  }, [code]);
  
  const renderInputs = () => {
    return code.map((value, index) => (
      <input
        key={index}
        type="tel"
        value={code[index]}
        onPaste={(e) => {
          e.preventDefault();
          const clipboardData = e.clipboardData || (window as any).clipboardData;
          const pastedData = clipboardData.getData('text');
          const newCode = [...code];
          const exp = /^[a-zA-Z0-9]+$/;

          for (let i = 0; i < 6; i++) {
            if (!exp.test(pastedData[i])) continue;

            newCode[i] = pastedData[i].toUpperCase();
          }

          setCode(newCode);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Backspace' && code[index] === '') {
            const element = document.querySelectorAll('#code-input input')[index - 1];
            if (element) {
              (element as HTMLInputElement)?.focus();
            }
          }

          if (e.key === 'Backspace' && code[index] !== '') {
            const newCode = [...code];
            newCode[index] = '';
            setCode(newCode);
          }
        }}
        onChange={(e) => {
          const newCode = [...code];
          const value = e.target.value;
          const exp = /^[a-zA-Z0-9]+$/;

          if (!exp.test(value)) return;

          newCode[index] = value[0].toUpperCase();
          setCode(newCode);

          if (e.target.value === '') {
            const element = document.querySelectorAll('#code-input input')[index - 1];
            if (element) {
              (element as HTMLInputElement)?.focus();
            }

            return;
          }

          const firstEmptyIndex = newCode.findIndex((value) => value === '');
          if (firstEmptyIndex === index + 1) {
            const element = document.querySelectorAll('#code-input input')[firstEmptyIndex];
            if (element) {
              (element as HTMLInputElement)?.focus();
            }
          }
        }}
      />
    ));
  };
  
  return (
    <CodeInputContainer id="code-input" length={props.length}>
      {renderInputs()}
    </CodeInputContainer>
  );
};

export default CodeInput;
