import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL;

interface LoginType {
  email: string;
  password: string;
}

/**
 * request to django api a run
 * @param id run reference on django api
 * @returns promise that contains a run
 */
export const loginRequest = ({ email, password }: LoginType) =>
  axios.post(`${API_URL}/auth/login/`, { email, password });

export const projectCodeLoginRequest = (code: string, fingerprint: string, timezone: string) => 
  axios.post(`${API_URL}/auth/guest/login/`, { project_code: code, fingerprint, timezone });

/**
 *
 * @param param0
 * @returns
 */
export const verifyToken = (token: any) => {
  return axios.post(
    `${API_URL}/auth/token/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
};

/**
 *
 * @param email
 * @returns
 */
export const requestPasswordChangeEmail = (email: string) =>
  axios.post(`${API_URL}/api/auth/forget-password/request/`, { email });

/**
 *
 * @param customerId
 * @param token
 * @returns
 */
export const verifySurveysAccess = (customerId: number, token: string) =>
  axios.get(`${API_URL}/customers/${customerId}/manager/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 *
 * @param password_request_token
 * @param password
 * @returns
 */
export const resetPassword = (
  password_request_token: string,
  password: string
) =>
  axios.post(`${API_URL}/api/auth/forget-password/reset/`, {
    password_request_token,
    password,
  });
