import styled from 'styled-components';

export const LoginContainer = styled.section`
  @media (min-width: 1024px) {
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 0;
  }
`;

export const LoginHeader = styled.header`
  padding: 22px 40px;
  border-bottom: 1px solid #a4abb6;
  
  img {
    height: 28px;
  }
`;

export const LoginForm = styled.form`
  min-width: 316px;

  display: grid;
  gap: 16px;

  border: 1px solid #A4ABB6;
  border-radius: 8px;
  padding: 24px;

  input {
    background: #f7f8f9;
  }

  footer {
    display: flex;
    justify-content: center;

    button {
      color: #363945;

      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
    }
  }
`;

export const LoginError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px;
  border-radius: 8px;
  background: #FDE8E8;
  color: #C92A2A;
`;

export const LoginMain = styled.main`
  padding: 40px;

  display: grid;
  grid-gap: 16px;
  align-items: start;
  justify-content: center;

  @media (min-width: 1024px) {
    > header {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

export const LoginFooter = styled.footer`
  text-align: center;

  > div {
    display: flex;
    justify-content: center;
    gap: 16px;

    margin-top: 45px;
    padding: 26px;

    border-top: 1px solid #A4ABB6;

    button {
      text-decoration: underline;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      color: #363945;
    }
  }
`;
