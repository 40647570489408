import styled from 'styled-components';

export const MapTemplate = styled.div`
  position: relative;
  height: 100%;
`;

export const MapWrapper = styled.div`
  height: 100%;

  // styling street view button
  .gm-svpc {
    width: 36px !important;
    height: 36px !important;
    background-color: rgba(255, 255, 255, 1) !important;
    border-radius: 4px !important;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px !important;

    backdrop-filter: blur(10px);
    margin-left: 43px;
    margin-top: 30px;

    img {
      width: 24px !important;
      height: 24px !important;
    }

    @media (min-width: 1024px) {
      margin-top: 0;
      margin-left: 0;
    }
  }

  .gmnoprint.gm-bundled-control {
    @media (max-width: 1023px) {
      left: 43px !important;
      top: 58px !important;
    }
  }


  // user avatars
  img.map-user-avatar {
    border: 2px solid white !important;
    box-shadow: 0px 1px 0px rgb(0 0 0 / 8%);
    border-radius: 50%;
  }

  img.map-my-user-avatar {
    border: 2px solid #4e46e9 !important;
    box-shadow: 0px 1px 0px rgb(0 0 0 / 8%);
    border-radius: 50%;
  }

  // styling labels
  .marker-label {
    top: 28px;
    left: 0;
    position: relative;
    background-color: #646464;
    padding: 3px;
    border-radius: 4px;
    z-index: 10;
  }
`;

export const ToggleShowingMapControllers = styled.div<{
  displayNone?: boolean;
}>`
  z-index: 10;
  position: absolute;
  top: 68px;
  left: 10px;

  height: 36px;
  width: 36px;
  background-color: #ffffff;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }

  ${({ displayNone }) => (displayNone ? 'display: none;' : '')}
`;
