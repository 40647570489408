import { ChangeEvent, FocusEvent, useState } from 'react';
import { MaterialIcon } from 'material-icons';
import {
  Group,
  GroupLabel,
  GroupInput,
  GroupError,
  GroupInputWrapper,
} from './password-input.style';

interface Props {
  id: string;
  name?: string;
  value: string;
  icon?: MaterialIcon;
  testid?: string;
  label?: string;
  visibilityTestId?: string;
  error?: string;
  noErrorText?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
};


/**
 * 
 * @param props 
 * @returns 
 */
const PasswordInput = ({
  id,
  name,
  value,
  error,
  noErrorText = false,
  placeholder,
  testid,
  label,
  disabled = false,
  onChange,
  onBlur,
  onFocus,
  visibilityTestId,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  
  /**
   * 
   */
  const handleIconClick = () => {
    setVisible(!visible);
  };

  return (
    <Group>
      {label ? <GroupLabel disabled={disabled}>{label}</GroupLabel> : null}
      <GroupInputWrapper error={!!error}>
        <GroupInput
          id={id}
          name={name}
          type={visible ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur ? onBlur : () => {}}
          onFocus={onFocus ? onFocus : () => {}}
          disabled={disabled}
          data-testid={testid}
        />
        <span data-testid={visibilityTestId} className="material-icons-outlined" onClick={handleIconClick}>
          {visible ? 'visibility' : 'visibility_off'}
        </span> 
      </GroupInputWrapper>
      {error && !noErrorText ? <GroupError>{error}</GroupError> : null}
    </Group>
  );
};

export default PasswordInput;
