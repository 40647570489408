import styled from 'styled-components';

export const GuestNameModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  width: 100%;
  height: 100vh;

  background-color: #4D5158D9;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); 
  z-index: 30;
`;

export const GuestNameModalContainer = styled.div`
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;

  width: 100%;
  max-width: 342px;
`;

export const GuestNameForm = styled.form`
  display: grid;
  gap: 16px;

  > button {
    width: 100%;
  }
`;
