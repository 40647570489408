import { useReducer, createContext, ReactNode } from 'react';
import { reducer } from './account.reducer';
import {
  AccountContext,
  AccountDispatchType,
  AccountProviderValue,
  PermissionType,
} from './account.context.d';

const initialAccountState: AccountContext = {
  auth: undefined,
  loading: false,
  verified: false, 
  permissions: [],
  register_data: undefined,
  opened_profile_modal: false,
}

// account permissions
export const permissions: { [key: string]: PermissionType } = {
  SURVEY_MANAGER: 'SURVEY_MANAGER',
  CUSTOMER_MANAGER: 'CUSTOMER_MANAGER',
  NO_GUEST: 'NO_GUEST',
};

const AccountStore = createContext({} as AccountProviderValue);

/**
 * 
 * @returns 
 */
export const AccountProvider = (props: { children: ReactNode, value?: AccountContext }) => {
  // Define state and dispatch method for this Context
  const reducerData = useReducer(reducer, { ...initialAccountState, ...props.value });
  const state: AccountContext = reducerData[0];
  const dispatch: (data: AccountDispatchType) => void = reducerData[1];

  return (
    <AccountStore.Provider value={{ state, dispatch }}>
      {props.children}
    </AccountStore.Provider>
  );
};


export default AccountStore;
