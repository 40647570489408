import React, { useState, useContext } from 'react';
import AccountStore from 'app/modules/account/account.context';
import Sidebar, { ActiveTypes } from './components/sidebar/sidebar';
import MyAccount from './components/contents/my-account/my-account';
import UnitsAndTimezone from './components/contents/units-and-timezone/units-and-timezone';
import Security from './components/contents/security/security';
import {
  ModalWrapper,
  Modal,
  ContentWrapper,
  CloseButton,
} from './profile-modal.style';

interface ContentsType {
  'my-account': React.FC;
  'units-and-timezone': React.FC;
  security: React.FC;
}

const ProfileModal = () => {
  const accountContext = useContext(AccountStore);
  const initalActive = accountContext.state.auth?.user?.is_guest ? 'units-and-timezone' : 'my-account';
  const [selected, setSelected] = useState<ActiveTypes>(initalActive);

  const contents: ContentsType = {
    'my-account': MyAccount,
    'units-and-timezone': UnitsAndTimezone,
    security: Security,
  };

  const Content: React.FC | null = contents[selected];
  
  return (
    <ModalWrapper
      $opened={accountContext.state.opened_profile_modal}
      onClick={() => {
        accountContext.dispatch({
          type: 'CLOSE_PROFILE_MODAL',
        });
      }}
    >
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Sidebar
          isGuest={accountContext.state.auth?.user?.is_guest || false}
          email={accountContext.state.auth?.user?.email || ''}
          active={selected}
          onActiveChange={(active: ActiveTypes) => setSelected(active)}
        />
        <ContentWrapper id="profile-content-wrapper">
          <CloseButton
            onClick={(e) => {
              e.stopPropagation();
              accountContext.dispatch({
                type: 'CLOSE_PROFILE_MODAL',
              });
            }}
          >
            <svg
              width="24"
              height="23"
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="16.9492"
                y="4.94971"
                width="2"
                height="16"
                transform="rotate(45 16.9492 4.94971)"
                fill="#99A2AD"
              />
              <rect
                x="18.3633"
                y="16.2634"
                width="2"
                height="16"
                transform="rotate(135 18.3633 16.2634)"
                fill="#99A2AD"
              />
            </svg>
          </CloseButton>
          {Content ? <Content /> : null}
        </ContentWrapper>
      </Modal>
    </ModalWrapper>
  );
};

export default ProfileModal;
