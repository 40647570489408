import { useContext, useState } from "react";
import InspectionStore from "app/modules/inspection/inspection.context";
import { ProjectCodeContainer } from "./project-code.style";

/**
 * 
 * @returns 
 */
const ProjectCode = () => {
  const inspectionContext = useContext(InspectionStore);
  const [copied, setCopied] = useState(false);
  const projectCode = inspectionContext.run?.project?.guest_code;
  const guestsEnabled = inspectionContext.run?.project?.guests_enabled;

  if (!guestsEnabled) {
    return null;
  }


  /**
   *  Copy the project code to the clipboard
   */
  const handleCopy = (event) => {
    const element = event.target;
    if (!projectCode || !element) return;
    navigator.clipboard.writeText(projectCode);
    
    const range = document.createRange();
    range.selectNodeContents(element);
    
    const selection = window.getSelection();
    if (!selection) return;    
    selection.removeAllRanges();
    selection.addRange(range);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  };
  
  return (
    <ProjectCodeContainer>
      <label>Project Code</label>
      <button disabled={copied} onClick={handleCopy}>
        {projectCode}
        {copied && <span>Copied!</span>}
      </button>
    </ProjectCodeContainer>
  );
};

export default ProjectCode;