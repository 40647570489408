import styled from 'styled-components';

export const Wrapper = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    background-color: #f7f8f9;
  }
`;

export const Email = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  color: #697386;
  padding: 10px 16px;
`;

export const ContentList = styled.ul``;

export const Option = styled.li<{ $active: boolean; $hide: boolean; }>`
  display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
  align-items: center;
  gap: 8px;

  padding: 10px 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1em;

  color: ${({ $active }) => ($active ? '#ffffff' : '#161e2e')};
  background-color: ${({ $active }) => ($active ? '#5850ec' : 'transparent')};
  cursor: pointer;

  transition-duration: 200ms;

  &:not(:nth-child(3)) > svg {
    width: 20px;
    height: 20px;
    fill: ${({ $active }) => ($active ? '#ffffff' : '#8e939e')};
  }

  &:hover:not(:nth-child(3)) {
    background-color: #5850ec;
    color: #ffffff;

    > svg {
      fill: #ffffff;
    }
  }

  &:nth-child(3) > svg {
    width: 20px;
    height: 20px;

    > path,
    > rect {
      stroke: ${({ $active }) => ($active ? '#ffffff' : '#8e939e')};
    }
  }

  &:nth-child(3):hover {
    background-color: #5850ec;
    color: #ffffff;

    > svg > path,
    > svg > rect {
      stroke: #ffffff;
    }
  }
`;
