import { useContext } from "react";
import InspectionStore from "app/modules/inspection/inspection.context";
import { MobileProjectCodeContainer } from "./mobile-project-code.style";

/**
 * 
 * @returns 
 */
const MobileProjectCode = () => {
  const inspectionContext = useContext(InspectionStore);
  const projectCode = inspectionContext.run?.project?.guest_code;
  const guestsEnabled = inspectionContext.run?.project?.guests_enabled;

  if (!guestsEnabled) {
    return null;
  }


  /**
   *  Copy the project code to the clipboard
   */
  const handleCopy = () => {
    const element = document.getElementById('guest-code');
    if (!projectCode || !element) return;
    navigator.clipboard.writeText(projectCode);
    
    const range = document.createRange();
    range.selectNodeContents(element);
    
    const selection = window.getSelection();
    if (!selection) return;    
    selection.removeAllRanges();
    selection.addRange(range);
  };
  
  return (
    <MobileProjectCodeContainer>
      <label>Project Code</label>
      <span id="guest-code">{projectCode}</span>
      <button onClick={handleCopy}>
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.48841 10.9107C1.26761 10.7848 1.08396 10.6029 0.95602 10.3833C0.828078 10.1637 0.760378 9.91417 0.759766 9.66002V2.46002C0.759766 1.66802 1.40777 1.02002 2.19977 1.02002H9.39977C9.93977 1.02002 10.2335 1.29722 10.4798 1.74002M3.63977 5.82026C3.63977 5.31098 3.84208 4.82256 4.20219 4.46244C4.56231 4.10233 5.05073 3.90002 5.56001 3.90002H11.7995C12.0517 3.90002 12.3014 3.94969 12.5344 4.04619C12.7673 4.14269 12.979 4.28413 13.1573 4.46244C13.3357 4.64076 13.4771 4.85244 13.5736 5.08542C13.6701 5.31839 13.7198 5.56809 13.7198 5.82026V12.0598C13.7198 12.3119 13.6701 12.5616 13.5736 12.7946C13.4771 13.0276 13.3357 13.2393 13.1573 13.4176C12.979 13.5959 12.7673 13.7373 12.5344 13.8339C12.3014 13.9304 12.0517 13.98 11.7995 13.98H5.56001C5.30784 13.98 5.05814 13.9304 4.82516 13.8339C4.59219 13.7373 4.3805 13.5959 4.20219 13.4176C4.02388 13.2393 3.88244 13.0276 3.78594 12.7946C3.68943 12.5616 3.63977 12.3119 3.63977 12.0598V5.82026Z" stroke="#5850EC" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    </MobileProjectCodeContainer>
  );
};

export default MobileProjectCode;