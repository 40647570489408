import styled from 'styled-components';

export const MobileProjectCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 8px;

  height: 23px;

  border-radius: 8px;
  border: 1px solid #a4abb6;
  background-color: #ececec;

  padding-left: 6px;
  padding-right: 6px;

  position: absolute;
  left: 8px;
  top: 36px;
  z-index: 10;

  > label {
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    color: #090808;
  }

  > span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
`;
