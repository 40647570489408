import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AcccountStore from 'app/modules/account/account.context';
import ProfileModal from 'app/components/profile-modal/profile-modal';
import { verifyAuth } from './private-wrapper.controller';
import GuestNameModal from './components/guest-name-modal/guest-name-modal';

const PUBLIC_PATHS_REGEXP = [
  /^\/$/, // REGEXP TO LOGIN PATH
  /^\/invitations/,
  /^\/register/,
  /^\/forgot-password/,
];

/**
 * 
 * @param location 
 */
const checkIsPublicPath = (location: string) => {
  let match = false;

  for (const regexp of PUBLIC_PATHS_REGEXP) {
    match = match || regexp.test(location);
  }

  return match;
};

/**
 *
 * @param param0
 * @returns
 */
const PrivateWrapper = ({ children }: any) => {
  const history = useHistory();
  const accountContext = useContext(AcccountStore);

  /**
   *
   */
  useEffect(() => {   
    if (!accountContext.state.verified) {
      accountContext.dispatch({
        type: 'SET_LOADING',
        data: true,
      });

      const token: string | null = window.localStorage.getItem('CDI@auth-token');
      verifyAuth(token || '', accountContext.dispatch);
    }

    const guestWithoutName = accountContext.state.auth?.user.is_guest &&
                            (
                              accountContext.state.auth?.user.first_name === 'Guest' ||
                              !accountContext.state.auth?.user.first_name
                            );
    if (
      accountContext.state.verified &&
      accountContext.state.auth?.user.is_guest &&
      guestWithoutName
    ) {
      let currentPath = history.location.pathname;
      
      // remove last slash if exists
      if (currentPath[currentPath.length - 1] === '/') {
        currentPath = currentPath.slice(0, -1);
      }

      history.push(`${currentPath}/?modal=guest-name`);
    }
  }, [accountContext.state.auth]);

  /**
   *
   */
  useEffect(() => {
    const isPublicPatch = checkIsPublicPath(history.location.pathname);
    if (accountContext.state.verified && !accountContext.state.auth && !isPublicPatch) {
      const url = `/?redirect=${encodeURIComponent(history.location.pathname)}`;
      history.push(url);
    }
  }, [accountContext.state.verified, accountContext.state.auth]);

  if (!accountContext.state.verified || !accountContext.state.auth) return null;

  return (
    <>
      {children}
      <ProfileModal />
      <GuestNameModal />
    </>
  )
};


export default PrivateWrapper;
