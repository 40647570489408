import styled from 'styled-components';

export const RunMobileContainer = styled.div<{ fullscreen: boolean }>`
  display: grid;
  width: 100%;
  height: calc(100vh - 42px);

  overflow: hidden;
  grid-template-rows: ${({ fullscreen }) => (fullscreen ? '1fr auto' : '1fr 300px')};
  will-change: grid-template-rows;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MapContainer = styled.div`
  position: relative;
`;

export const Content = styled.div<{ collapsed: boolean }>`
  width: 100vw;
  position: relative;
  background: linear-gradient(180deg, #93A3BC 0%, #EDEDED 89.76%);
  padding-bottom: ${({ collapsed }) => (collapsed ? '0' : '12px')};

  width: 100vw;
`;

export const CollapseButtonWrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  justify-content: center;

  width: 100%;
  height: ${({ collapsed }) => (collapsed ? '36px' : '0')};
  will-change: height;

  border-radius: 6px 6px 0px 0px;

  background-color:  ${({ collapsed }) => (collapsed ? '#5850ec' : '#ffffff')};
`;

export const CollapseButton = styled.button<{ collapsed: boolean }>`
  position: relative;
  top:  ${({ collapsed }) => (collapsed ? '-16px' : '-9px')};

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  background-color: #5850ec;
  border-radius: 50%;
  border: solid 1px #ffffff;

  transform: rotate(${({ collapsed }) => (collapsed ? '180deg' : '0')});
  transition-duration: 400ms;

  > svg {
    width: 14px;
  }
`;

export const Views = styled.div<{ $collapsed: boolean }>`
  display: ${({ $collapsed }) => ($collapsed ? 'none' : 'grid')};
  grid-template-columns: 100% 100%;
  grid-template-rows: 100%;
  gap: 16px;

  padding-left: 16px;
  padding-right: 16px;

  height: calc(100% - 20px);
  overflow: hidden;
`;

export const View = styled.div<{ $view: string }>`
  transform: translateX(${({ $view }) => ($view === 'editor' ? '0' : 'calc(-100% - 16px)')});
  transition-duration: 400ms;

  height: 100%;
`;

export const LaunchButton = styled.button<{ $loading: boolean }>`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  position: absolute;
  top: -120px;
  left: calc(50% - 40px);

  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color:  ${({ $loading }) => ($loading ? 'rgba(62, 193, 130, 1)' : 'rgba(62, 193, 130, 1)')};
  overflow: hidden;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size:  ${({ $loading }) => ($loading ? '10px' : '12px')};
  line-height: 12px;
  text-transform: uppercase;

  color: #ffffff;
  box-shadow: 0px 0px 0px 6px ${({ $loading }) => ($loading ? 'rgba(62, 193, 130, 1)' : 'rgba(62, 193, 130, 1)')};

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top:  ${({ $loading }) => ($loading ? '0' : '1.5px')};
    left:  ${({ $loading }) => ($loading ? '0' : '1.5px')};

    display: block;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;

    border: solid #ffffff;
    border-width: ${({ $loading }) => ($loading ? '3px' : '1.5px')};
    border-top-color: ${({ $loading }) => ($loading ? 'rgba(255, 255, 255, 0.7)' : '#ffffff')};

    transition-duration: 400ms;
    animation: ${({ $loading }) => ($loading ? 'rotate 1s linear infinite' : 'none')};
  }
`;

export const TrackingPointList = styled.div`
  display: grid;
  height: 100%;

  padding-top: 16px;
  padding-bottom: 16px;

  gap: 16px;
  overflow: auto;

  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    scroll-snap-stop: always;
    scroll-snap-align: center;
    scroll-margin: 16px;

  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ViewTab = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #ffffff;

  ${({ $active }) => {
    if (!$active) return;
    return `
      box-shadow: inset 0px -3px 0px -1px #ffffff;
    `;
  }}
`;

export const ViewTabs = styled.div<{ $collapsed: boolean }>`
  display: ${({ $collapsed }) => ($collapsed ? 'none' : 'grid')};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  box-shadow: 0px 6px 10px rgba(51, 57, 78, 0.2);

  background-color: #5850ec;
  height: 36px;
`;

export const MobileQubesButton = styled.button`
  z-index: 10;
  position: absolute;
  top: 110px;
  left: 10px;

  height: 36px;
  width: 36px;
  background-color: #474c5e;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
