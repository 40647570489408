import { useEffect, useState } from 'react';
import differenceBy from 'lodash/differenceBy';
import { PartipantType } from 'app/services/user.service';
import { ProjectType } from 'app/interfaces/inspection.interfaces';
import Button from 'app/components/button/button';
import Checkbox from 'app/components/checkbox/checkbox';
import Avatar from 'app/components/avatar/avatar';
import RoleSelector, { RoleType } from '../role-selector/role-selector';
import {
  Ball,
  CloseButton,
  Email,
  EmptyProjectsWrapper,
  EmptyText,
  ModalWrapper,
  Modal,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Name,
  NameWrapper,
  ProjectList,
  ProjectListHeader,
  ProjectListItem,
  ProjectListTitle,
  UserInformations,
} from './project-access.style';
import { editUserPermissions } from './project-access.controller';

interface Props {
  opened: boolean;
  avatarUri?: string;
  token: string;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  participantProjects: PartipantType[];
  managerProjects: ProjectType[];
  onClose: () => void;
  onSuccess: () => void;
}

const renderItems = (
  managerProjects: ProjectType[],
  participants: PartipantType[],
  handleChangeRole: (role: RoleType, projectId: number) => void,
  handleCheckChange: (checked: boolean, projectId: number) => void,
  focusedProject: number | null,
  setFocusedProject: (id: number | null) => void
) =>
  managerProjects.map((project) => {
    const participant = participants.find(
      (participant) => participant.project.id === project.id
    );

    const roles = [
      { id: 'admin', editor: true, admin: true, label: 'Project Manager' },
      { id: 'editor', editor: true, admin: false, label: 'Editor' },
      { id: 'observer', editor: false, admin: false, label: 'Observer' },
    ];

    return (
      <ProjectListItem key={project.id} focus={project.id === focusedProject}>
        {project.name}
        <div>
          <RoleSelector
            selected={
              (roles.find(
                (role) =>
                  role.editor === participant?.editor &&
                  role.admin === participant?.admin
              ) as RoleType) || (roles[1] as RoleType)
            }
            onBlur={() => {
              setFocusedProject(null);
            }}
            onFocus={() => {
              setFocusedProject(project.id);
            }}
            onSelect={(role) => {
              if (role && project.id) handleChangeRole(role, project.id);
            }}
          />
          <Checkbox
            checked={
              participants.findIndex(
                (participant) => participant.project.id === project.id
              ) !== -1
            }
            onClick={() => {
              const index = participants.findIndex(
                (participant) => participant.project.id === project.id
              );

              if (project.id) handleCheckChange(index === -1, project.id);
            }}
          />
        </div>
      </ProjectListItem>
    );
  });

const ProjectAccess = ({
  opened,
  token,
  avatarUri,
  id,
  firstName,
  lastName,
  email,
  participantProjects,
  managerProjects,
  onSuccess,
  onClose,
}: Props) => {
  const [selectedParticipantProjects, setSelectedParticipantProjects] =
    useState<PartipantType[]>([]);
  const [focusedProject, setFocusedProject] = useState<number | null>(null);

  useEffect(() => {
    if (opened) {
      setSelectedParticipantProjects(participantProjects);
    }
  }, [opened]);

  const handleChangeRole = (role: RoleType, projectId: number) => {
    const projects = [...selectedParticipantProjects];

    const index = projects.findIndex(
      (participant) => participant.project.id === projectId
    );

    if (index !== -1) {
      projects[index] = {
        ...projects[index],
        admin: role.admin,
        editor: role.editor,
      };
    } else {
      const mProject = managerProjects.find(
        (project) => project.id === projectId
      );

      if (!mProject) return;

      projects.push({
        project: mProject,
        admin: role.admin,
        editor: role.editor,
      });
    }

    setSelectedParticipantProjects(projects);
  };

  const handleCheckChange = (checked: boolean, projectId: number) => {
    const projects = [...selectedParticipantProjects];
    const mProject = managerProjects.find(
      (project) => project.id === projectId
    );
    const index = projects.findIndex(
      (participant) => participant.project.id === projectId
    );

    if (!mProject) return;

    if (checked) {
      projects.push({
        project: mProject,
        admin: false,
        editor: true,
      });
    } else {
      projects.splice(index, 1);
    }

    setSelectedParticipantProjects(projects);
  };

  const handleToggleAllCheck = () => {
    if (managerProjects.length === selectedParticipantProjects.length) {
      setSelectedParticipantProjects([]);
    } else {
      const diff = differenceBy(
        managerProjects,
        selectedParticipantProjects.map(({ project }) => ({ ...project })),
        'id'
      );

      setSelectedParticipantProjects([
        ...selectedParticipantProjects,
        ...diff.map((project) => ({
          project,
          admin: false,
          editor: true,
        })),
      ]);
    }
  };

  return (
    <ModalWrapper opened={opened} onClick={onClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Manage Project Access</ModalTitle>
          <ModalDescription>
            Manage project access and user roles
          </ModalDescription>
          <UserInformations>
            <NameWrapper>
              <Avatar
                src={avatarUri}
                email={email || 'A'}
                firstName={firstName}
                lastName={lastName}
              />
              <Name>{`${firstName} ${lastName}`}</Name>
            </NameWrapper>
            <Ball />
            <Email>{email}</Email>
          </UserInformations>
          <CloseButton onClick={onClose}>
            <svg
              viewBox="0 0 23 23"
              width="23"
              height="23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="16.2617"
                y="4.94922"
                width="2"
                height="16"
                transform="rotate(45 16.2617 4.94922)"
                fill="#99A2AD"
              />
              <rect
                x="17.6758"
                y="16.2637"
                width="2"
                height="16"
                transform="rotate(135 17.6758 16.2637)"
                fill="#99a2ad"
              />
            </svg>
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          <ProjectList>
            <ProjectListHeader>
              <ProjectListTitle>Projects</ProjectListTitle>
              <Checkbox
                checked={
                  managerProjects.length === selectedParticipantProjects.length
                }
                onClick={handleToggleAllCheck}
              />
            </ProjectListHeader>
            {managerProjects.length ? (
              renderItems(
                managerProjects,
                selectedParticipantProjects,
                handleChangeRole,
                handleCheckChange,
                focusedProject,
                setFocusedProject
              )
            ) : (
              <EmptyProjectsWrapper>
                <EmptyText>There are no projects you can manage.</EmptyText>
              </EmptyProjectsWrapper>
            )}
          </ProjectList>
        </ModalContent>
        <ModalFooter>
          <Button
            value="Save"
            customStyle={`
              width: 120px;
            `}
            handleClick={() => {
              editUserPermissions(
                selectedParticipantProjects,
                id.toString(),
                token,
                onSuccess
              );
            }}
          />
        </ModalFooter>
      </Modal>
    </ModalWrapper>
  );
};

export default ProjectAccess;
