import styled from 'styled-components';

export const GtLogoIMG = styled.img`
  height: 30px;
`;

export const GtLogoNameSVG = styled.svg`
  height: 22px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;
