import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  GuestNameModalContainer,
  GuestNameModalWrapper,
  GuestNameForm,
} from "./guest-name-modal.style";
import FilledButton from "stories/base/buttons/filled-button/filled-button";
import AccountStore from "../../account.context";
import QueryString from "qs";
import Typograph from "stories/type/typograph/typograph";
import Input from "stories/base/forms/input/input";
import { updateGuestName } from "app/services/user.service";
import { UserType } from "app/interfaces/account.interfaces";

/**
 * 
 * @returns 
 */
export const GuestNameModal = () => {
  const history = useHistory();
  const location = useLocation();
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth;
  
  const [guestName, setGuestName] = useState<string>('');
  
  const modal = useMemo(() => {
    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    return query.modal;
  }, [location]);

  if (!auth?.user.is_guest || modal !== 'guest-name') {
    return null;
  }

  /**
   * 
   * @param event 
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    const first_name = guestName.split(' ')[0];
    const last_name = guestName.split(' ').slice(1).join(' ');
    
    if (!auth?.user) return;

    const user = { ...auth.user, first_name, last_name };

    const response = updateGuestName(auth.user.id, user, auth.token);
    const updatedUser = (await response).data as UserType;
    const nAuth = { ...accountContext.state.auth, user: updatedUser };
    accountContext.dispatch({ type: 'LOGIN', data: nAuth });
    
    const pathname = history.location.pathname;
    history.push(pathname.replace('modal=guest-name', ''));
  };
    
  /**
   * 
   */
  const handleGuestNameChange = (event) => {
    setGuestName(event.target.value);
  };

  return (
    <GuestNameModalWrapper>
      <GuestNameModalContainer>
        <GuestNameForm onSubmit={handleSubmit}>
          <Typograph type="headline6">What&apos;s your name?</Typograph>
          <Input placeholder="Enter your name" id="guest-name" value={guestName} onChange={handleGuestNameChange} />
          <FilledButton type="submit" text="Enter" />
        </GuestNameForm>
      </GuestNameModalContainer>
    </GuestNameModalWrapper>
  );
};

export default GuestNameModal;
